<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Operadores</h1>
    <div v-if="$store.state.user.admin" class="grid grid-cols-2 md:grid-cols-6 gap-3">
      <router-link :to="`/${route}/form`" type="button"
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="mb-2 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
        <span class="inline-block mr-2">{{ $t("txt-new") }}</span>
      </router-link>
      <router-link :to="`/${route}/msgproibida`" type="button"
        class="mb-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
        <span class="inline-block mr-2">Mensagem proibidas</span>
      </router-link>
      <router-link :to="`/${route}/numerosproibido`" type="button"
        class="mb-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
        <span class="inline-block mr-2">Números proibidos</span>
      </router-link>
    </div>
    <form class="mb-2 mt-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3">
          <label for="busca" class="block text-sm font-medium text-gray-700">Nome ou email do operador</label>
          <input v-model="busca" type="text" name="busca"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
          <label for="dataDisparo" class="block text-sm font-medium text-gray-700">
            Centro de custo
          </label>
          <v-select v-model="centrocusto" :options="centroscustos" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-2">
          <button @click="setPage(1)" type="button"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
        <div v-if="$store.state.user.admin" class="col-span-12 md:col-span-2">
          <a :href="`${api}/v1/operador/download/operadores?token=${$store.state.token}&centrocusto=${centrocusto}&ativo=${!desativados}`"
            target="_blank" role="button" type="button"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Download</span>
          </a>
        </div>
        <div v-if="$store.state.user.admin" class="text-red-600 col-span-12 md:col-span-4 mt-auto font-semibold flex flex-row-reverse">
          <small class="mr-2">Total Uploader: {{ totalUpload }}</small>
          <small class="mr-2">Total Admin: {{ totalAdmin }}</small>
          <small class="mr-2">Total: {{ total }}</small>
        </div>
      </div>
      <label v-if="$store.state.user.admin" class="font-semibold text-zinc-600 cursor-pointer text-sm">
        <input type="checkbox" class="cursor-pointer mr-1" @input="buscaPorDesativados()" />
        Listar desativados
      </label>
    </form>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>

                  <th scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>

                  <th scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ADMIN
                  </th>

                  <th scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>

                  <th scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Centro de Custo
                  </th>
                  
                  <th v-if="desativados" scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data Desativado
                  </th>

                  <th v-if="$store.state.user.admin" scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="operador in list" :key="operador._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ operador.nome ? operador.nome : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3"
                    :class="operador.onlinelzv2 ? 'text-green-500' : 'text-red-500'">
                    {{ operador.onlinelzv2 ? "Online" : "Offline" }}
                    <span v-if="operador.horaonline">
                      desde:
                      {{ operador.horaonline | moment("DD/MM/YYYY HH:mm") }}</span>
                    <button @click="mudarStatus(operador)"
                      class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm mx-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-xs shadow-sm hover:shadow-md font-semibold text-center">
                      Mudar status
                    </button>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ operador.admin ? "Sim" : "Não" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ operador.email ? operador.email : "" }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{
                      operador.centrocusto && operador.centrocusto.nome
                        ? operador.centrocusto.nome
                        : ""
                    }}
                  </td>
                  <td v-if="desativados" class="text-sm 2xl:text-base px-2 py-3">
                    <span class="inline-block">
                      {{ operador.dataDesativado ? `${moment(operador.dataDesativado).format("DD/MM/YYYY HH:mm")}` : 'Sem data vinculada'
                      }}</span>
                  </td>
                  <td v-if="!desativados && $store.state.user.admin" class="text-sm 2xl:text-base px-2 py-3">
                    <router-link :to="`/${route}/avaliacoes/${operador._id}`" type="button"
                      class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 mx-2 focus:shadow-sm focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      <span class="inline-block">Avalições</span>
                    </router-link>
                    <button @click="modalTransferir(operador)" type="button"
                      class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm mx-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      Transferir conversas
                    </button>
                    <router-link :to="`/${route}/form/${operador._id}`" type="button"
                      class="bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      <span class="inline-block">Editar</span>
                    </router-link>
                    <button @click="remove(operador._id)" type="button"
                      class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm mx-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      Remover
                    </button>
                  </td>
                  <td v-if="desativados">
                    <button @click="ativarOperador(operador._id)"
                      class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm mx-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center">
                      Ativar Operador
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <modal name="transferirConversas" height="auto">
      <div class="px-3 py-4">
        <h2 class="text-lg mb-2 font-bold">Transferir conversas</h2>
        <label for="operador" class="block text-sm font-medium text-gray-700">Operador</label>
        <select v-model="transferir.para" id="operador" name="operador"
          class="mt-1 block w-full py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option v-for="(p, idx) in list" :key="idx" :value="p._id">
            {{ p.nome }}
          </option>
        </select>
        <button @click="transferirConversas()" type="button"
          class="mt-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
          Transferir
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import moment from "moment";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      moment: moment,
      breadcrumb: [{ url: "/operadores", value: "Operadores" }],
      transferir: {
        operador: null,
        para: null,
      },
      desativados: false,
      page: 1,
      perPage: 25,
      busca: "",
      centroscustos: [],
      centrocusto: null,
      route: "operadores",
      list: [],
      total: 0,
      totalAdmin: 0,
      totalUpload: 0,
    };
  },
  methods: {
    async setPage(page) {
      this.page = page;

      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      console.log(this.centrocusto)
      const operadoresReq = await this.$http.post(`/v1/operador/list`, {
        busca: this.busca,
        centrocusto: this.centrocusto,
        desativados: this.desativados,
        limit,
        skip
      });

      this.list = operadoresReq.data.data;
      this.total = operadoresReq.data.info.total;
      this.totalAdmin = operadoresReq.data.info.totalAdmin;
      this.totalUpload = operadoresReq.data.info.totalUpload;
    },

    async buscaPorDesativados() {
      this.desativados = !this.desativados;
      await this.start();
    },

    async start() {
      await this.setPage(this.page);
    },

    async modalTransferir(operador) {
      const { _id, centrocusto } = operador;

      this.centrocusto = centrocusto;
      this.transferir.operador = _id;
      this.$modal.show("transferirConversas");
    },

    async transferirConversas() {
      if (!this.transferir.para) return this.$vToastify.error("Selecione o operador");
      if (!this.transferir.operador) return this.$vToastify.error("Selecione o operador");

      const resp = await this.$http.post(`/v1/operador/transferir`, {
        _id: this.transferir.operador,
        para: this.transferir.para,
        centrocusto: this.centrocusto
      });

      if (!resp.data.success) {
        this.$vToastify.error(resp.data.err);
        return;
      }

      this.$modal.hide("transferirConversas");
      this.$vToastify.success("Atualizado com sucesso");
      this.start();
    },

    async mudarStatus(operador) {
      if (confirm("Mudar status do operador?")) {
        const resp = await this.$http.put(`/v1/operador`, {
          _id: operador._id,
          onlinelzv2: !operador.onlinelzv2,
        });
        if (!resp.data.success) {
          this.$vToastify.error(resp.data.err);
          return;
        }
        this.$vToastify.success("Atualizado com sucesso");
        this.start();
      }
    },

    async ativarOperador(id) {
      if (confirm("Ativar operador?")) {
        const response = await this.$http.put(`/v1/operador`, {
          _id: id,
          ativarOperador: true
        });

        if (!response.data.success) {
          this.$vToastify.error(response.data.err);
          return;
        }

        this.$vToastify.success("Ativado com sucesso");
        this.start();
      }
    },

    async remove(operador) {
      if (confirm("Tem certeza que deseja excluir esse operador?")) {
        const resp = await this.$http.delete(`/v1/operador/${operador}`);
        if (!resp.data.success) {
          this.$vToastify.error(resp.data.err);
          return;
        }
        this.$vToastify.success("Operador removido");
        this.start();
      }
    },
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscustos = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }
    this.start();
  },
};
</script>
